import "./App.css";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import abi from "./abi.json";

function App() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0xEBa8B372776397eF3A030e3Cd0c58B9D2Ad3A449"
    );

    setInstance(ins);

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.tokenCount().call();
        console.log(tokenCount);
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/starbirds.io");
      alert("Meta Mask not detected");
    }
  }

  // Create a provider to interact with a smart contract
  async function connectWallet() {
    if (typeof window.ethereum !== "undefined") {
      await requestAccount();

      const provider = new ethers.providers.Web3Provider(window.ethereum);
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 6) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <div className="mint-page">
      <Container>
        <div className="navb">
          {/* <a href="" target="_blank" rel="noreferrer"> */}
          <div className="">
            <img
              className="img-fluid logo"
              src={require("./assets/img/3.png").default}
              alt=""
            />
          </div>

          {/* </a> */}
          <div className=" text-center rmt">
            <div onClick={requestAccount} className="btn cusbtn">
              <span className="font-weight-bold text-uppercase">
                {add ? add.substring(0, 15) : "Connect Wallet"}
              </span>
            </div>
          </div>
        </div>
      </Container>
      <div className="mintbox pb-5">
        <Container className="d-flex justify-content-center">
          <div className="mint">
            <div className="">
              <h1 className="mih1 text-center  text-uppercase mb-3">
                Mint Your NFT's
              </h1>
              <p className="subtitle p-0 m-0 text-center  text-uppercase">
                Max Per Transaction = 6
              </p>
              <p className="subtitle p-0 m-0 text-center  text-uppercase">
                6 Per Wallet Limit
              </p>
              <div className="d-flex justify-content-center align-items-center mt-5">
                <div className="mx-3">
                  <img
                    onClick={minus}
                    className="plus-img"
                    src={require("./assets/img/4.png").default}
                    alt=""
                  />
                </div>
                <div className="display-number d-flex justify-content-center align-items-center">
                  <span className="number">{mintNumber}</span>
                </div>
                <div className="mx-3">
                  <img
                    onClick={plus}
                    className="plus-img"
                    src={require("./assets/img/2.png").default}
                    alt=""
                  />
                </div>
              </div>
              <div className="text-center mt-5">
                <button
                  // onClick={mint}
                  className="mintbutton btn  text-uppercase"
                >
                  Sold Out
                  {/* Coming Soon */}
                </button>
              </div>
              <div className="text-center mt-4">
                <h5 className="font-weight-bold bfont">Mint Price: Free</h5>
                <h3 className="font-weight-bold bfont">
                  {" "}
                  {totalMinted} / 3000
                </h3>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default App;
